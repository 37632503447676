import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Register from "views/pages/register/index";
import TermsConditions from "views/pages/termsconditions";
import ReturnPolicy from "views/pages/returnpolicy";
import Error404 from "views/pages/error/Error404";
import PrivacyPolicy from "views/pages/privacypolicy";
import Welcome from "views/pages/welcome";
import ResultPage from "views/pages/resultPage";
import ActivationPage from "views/pages/activationpage";

export default function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Welcome />} />
      <Route path="/register" element={<Register />} />
      <Route path="/result" element={<ResultPage />} />
      <Route path="/termsconditions" element={<TermsConditions />} />
      <Route path="/returnpolicy" element={<ReturnPolicy />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="*" element={<Error404 />} />
      <Route path="/activation" element={<ActivationPage />} />
    </Routes>
  );
}
