import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

import Footer from "components/Footers/Footer";
import { useLocation } from "react-router-dom";

function ResultPage() {
  const location = useLocation();
  const message = location.state;

  return (
    <div
      style={{ backgroundColor: "#f8f4ed", minHeight: "100vh" }}
      className="d-flex flex-column justify-content-center"
    >
      <Container className="ResultPage mt-auto w-100">
        <Row className=" justify-content-center">
          <Col xl="10">
            <div className="text-center text-white">
              <img
                width="60%"
                className="img-fluid align-self-center"
                src={require("assets/img/logo/MJ-Logo-Brown.png")}
                alt="Megan Jewellery"
              />
              <h4 className="mt-2 mb-5" style={{ fontSize: "1.5em" }}>
                {message.toLowerCase().includes("invalid")
                  ? "Success! You have been registered, and we will place you in the queue. Please wait for our update"
                  : message}
              </h4>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
}

export default ResultPage;
