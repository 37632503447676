import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";

const propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  loading: PropTypes.bool,
  spinColor: PropTypes.string,
  size: PropTypes.string,
};

function ButtonLoader({
  children = null,
  disabled = false,
  icon = null,
  loading = false,
  spinColor = "primary",
  size = "sm",
  ...rest
}) {
  function renderIcon() {
    if (loading) {
      return <Spinner color={spinColor} size={size} />;
    }

    return icon;
  }

  const buttonDisabled = disabled || loading;

  return (
    <Button disabled={buttonDisabled} {...rest} color="transparent">
      {renderIcon()}
      {children}
    </Button>
  );
}

ButtonLoader.propTypes = propTypes;
ButtonLoader.defaultProps = {
  children: null,
  disabled: false,
  icon: null,
  loading: false,
  spinColor: "primary",
  size: "sm",
};

export default ButtonLoader;

export { ButtonLoader, Spinner };
