import Footer from "components/Footers/Footer";

import { Container } from "reactstrap";
import React from "react";

function TermsConditions() {
  return (
    <div>
      <div
        className="section py-5"
        style={{
          backgroundColor: "#F5F0E5",
          backgroundPosition: "center",
        }}
      >
        <h1 className="text-center font-weight-bold">Terms & Conditions</h1>
      </div>
      <Container>
        <h5 className="mt-5 font-weight-bold megan-text">1. Applicability</h5>
        <p className="text-justify font-weight-normal">
          These general terms and conditions apply to all orders through
          www.meganjewellery.com (“the Website”). Please read these General
          Terms and Conditions carefully before ordering products from this
          Website. By ordering our products, you indicate that you consent to
          the conditions below and state that your are competent to enter into
          legally-binding contracts and are at least 18 years old. We recognize
          rules that differ from these General Terms and Conditions only if they
          are confirmed by us in writing. That also applies to a deviation from
          this clause requiring written documents.
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text ">
          2. Contracting Party
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          This Website for selling goods over the Internet is run by Megan
          Jewellery Sdn Bhd. This is where you can place complaints or assert
          statutory warranty claims.
        </p>
        <p className="text-justify pt-2 font-weight-normal">
          You can reach our customer service on any issues at:
          info@meganjewellery.com
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text ">
          3. Formation of Contract
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          Your orders on the Website are binding offers for entering into the
          associated contracts to purchase with Megan Jewellery Sdn Bhd. We are
          free to either accept these offers within 3 working days of receipt or
          to reject them. There is no binding contract to purchase formed until
          we have accepted your order. This acceptance is indicated by the
          shipment of the goods ordered.
        </p>
        <p className="text-justify font-weight-normal">
          You indicate that you expressly agree to have your credit rating
          checked by inquiries to the officially-authorized credit protection
          associations, credit institutions and credit inquiry agencies. We
          reserve the right to refuse to accept the order if we receive a
          negative credit report. In addition, we reserve the right to reject
          orders from customers if there is reason to believe that the customer
          is acting in contravention of the General Terms and Conditions, is
          involved in fraudulent activities or other activities of importance
          from a criminal law standpoint or any other material reason exists.
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text ">4. Availability</h5>
        <p className="text-justify pt-2 font-weight-normal">
          Products and prices are as shown on the Website; products are
          available as long as they are in stock. We reserve the right to change
          the products offered on the Website at any time without notice and to
          limit the number of items that may be purchased by a visitor.
        </p>
        <p className="text-justify font-weight-normal">
          There is no liability for lack of items in stock or for products not
          being available.
        </p>
        <p className="text-justify font-weight-normal">
          Any commercial resale or distribution of Megan Jewellery products sold
          on the Website is strictly prohibited.
        </p>
        <p className="text-justify font-weight-normal">
          Megan Jewellery reserves the right to limit the number of products (or
          the number of a particular SKU) that may be sold to a customer.
        </p>
        <p className="text-justify font-weight-normal">
          Megan Jewellery reserves the right to reject orders from customers if
          there is reasonable belief that customer is in violation of these
          Terms and Conditions, or if customer is engaging in fraudulent or
          other criminal activities.
        </p>
        <p className="text-justify font-weight-normal">
          Please note that after your order is confirmed, no requests to change
          or cancel it can be considered.
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text ">
          5. Reservation of Performance for Undeliverable Goods
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          If the goods ordered cannot be delivered within 15 working days
          following the day after your order, we may withdraw from the contract,
          even if we previously confirmed that we were entering into it. If we
          withdraw, you will be informed that the goods are not available and
          you will be reimbursed immediately for the consideration you have
          already provided.
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text ">
          6. Price and Additional Costs
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          The total price of your order, including all ancillary and shipping
          costs, was indicated on the previous page or will be shown at the end
          of the checkout process. By confirming the order, you state that you
          agree to the price for the goods. The price cannot be adjusted after
          the completion of the check-out process.
        </p>
        <p className="text-justify font-weight-normal">
          Megan Jewellery products are sold by Megan Jewellery Sdn Bhd, which
          has its headquarters in Malaysia. We would like to point out that for
          foreign payments using credit cards, a foreign transaction fee may be
          charged by the institution that issued your credit card.
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text ">
          7. Payment Conditions
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          The purchase price for goods ordered is due when you place your order
          through Megan Jewellery Online. Payment may be by credit card (by
          entering your card data on the Website)
        </p>
        <p className="text-justify font-weight-normal">
          If you pay with a credit card, we reserve the right to check the
          validity of the card and to monitor the availability parameters for
          collection and the address data. If the institution that issued the
          credit card refuses to make payment to us, we are not liable for
          delays or for failure to deliver.
        </p>
        <h5 className=" pt-2 font-weight-bold ">Prepayment:</h5>
        <p className="text-justify pt-2 font-weight-normal">
          For special orders (e.g. high order amount, Limited Editions,
          Pre-order) Megan Jewellery offers the possibility to pay via
          prepayment. Your order will be processed upon receipt of your payment
          into our bank account. The amount is due for payment immediately.
        </p>
        <p className="text-justify font-weight-normal">
          Please transfer the amount to the account listed in the checkout
          process using your order number as payment reference. Please note that
          your bank may charge additional costs for international bank
          transfers.
        </p>
        <p className="text-justify font-weight-normal">
          In case the payment amount is not transferred to our bank account
          within 2 banking days after receiving the order confirmation, Megan
          Jewellery has the right to cancel your order.
        </p>
        <p className="text-justify font-weight-normal">
          An invoice will also be sent with your package. Prepayment is not
          available for Electronic Gift Cards.
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text megan-text ">
          8. Delivery and Reservation of Title
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          The goods ordered are normally delivered to the delivery address you
          provide by our contracted delivery service within 5-10 working days
          after the working day following your order. Please allow an additional
          delivery time of 2-3 working days for customized products (if
          applicable). You can find our holidays, on which no orders are
          processed, in our Online Announcement Bars. If you so request, we will
          make efforts to deliver the goods at a specific time, but generally
          cannot make binding promises to do so. Until the purchase price is
          paid in its entirety, the goods remain our property.
        </p>
        <p className="text-justify font-weight-normal">
          Megan Jewellery is not liable to any damage caused by the third party
          logistic company.
        </p>
        <p className="text-justify font-weight-normal">
          Upon receipt the shipment must be checked for accuracy and
          completeness.
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text ">
          9. Right to Return
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          All returns must be authorized by Megan Jewellery prior to receipt.
          Product must be returned within 7 days of upon receipt and with
          product tag(s) attached. Authorizations are valid for 10 working days.
          Return goods authorizations (RGAs) may be arranged either emailing
          info@meganjewellery.com or by contacting a Megan Jewellery sales
          representative. Unauthorized returns may be returned to customer at
          customer’s expense, or subject to additional charges without credit
          being issued to customer. This policy applies to all customers unless
          superseded by a separate written agreement that includes specific
          return goods terms and conditions.
        </p>
        <p className="text-justify font-weight-normal">
          You can get additional information and assistance in returning items
          that have been ordered and the return address on our email:
          info@meganjewellery.com. The cancellation may be sent in writing or on
          another long-lasting storage medium to the address in clause 2 above
          or by sending the goods to the address listed on our Website. Timely
          sending is sufficient to meet the deadline. The products must be in
          original condition with their original packaging and the return
          voucher must be attached to the products.
        </p>
        <p className="text-justify font-weight-normal">
          Should your return goods authorizations (RGAs) has been successfully
          arranged, shipment is at the cost and risk of the consumer, but for
          orders up to MYR 200, you must pay the regular costs (uninsured
          standard shipment) for the return shipment unless the goods delivered
          were not the ones ordered. The return shipment must be made no later
          than 7 days after return of good has been announced.
        </p>
        <p className="text-justify font-weight-normal">
          The costs will be reimbursed only if the goods are received unused and
          in original condition via E-wallet at the address indicated above.
          Please note that if you pay by gift card, the amount paid can be
          reimbursed only via E-wallet. If partial payment is made by voucher,
          the voucher will first be recharged and then you will be credited.
        </p>
        <p className="text-justify font-weight-normal">
          Vouchers and customized products can generally not be replaced or
          returned.
        </p>
        <p className="text-justify font-weight-normal">
          Given the resolution of photos displayed online could be compressed
          due to different devices are being used to view the product, Megan
          Jewellery is not liable to any product return if product look
          different from photo revealed.
        </p>
        <p className="text-justify font-weight-normal">
          It is not currently possible to return goods purchased in Megan
          Jewellery shops through the Website.
        </p>
        <h5 className="pt-2 font-weight-bold megan-text ">10. Warranty</h5>
        <p className="text-justify pt-2 font-weight-normal">
          If there is a defect in the goods delivered, you may at your option
          demand that the contract be revoked or – if the goods are still
          deliverable – that they be replaced with the same type of good without
          any defects. No independent guarantee is provided in addition to that.
        </p>
        <p className="text-justify font-weight-normal">
          The warranty period is 2 days from the time the goods are received.
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text ">11. Liability</h5>
        <p className="text-justify pt-2 font-weight-normal">
          Megan Jewellery Sdn Bhd is not liable to you for damage caused
          intentionally or by gross negligence.
        </p>
        <p className="text-justify pt-2 font-weight-normal">
          This limitation of liability applies to all claims for damages,
          regardless of the legal basis, to include unauthorized actions; they
          do not apply to any liability that may apply under the Product
          Liability Act, some other warranted characteristic or injury to life
          or limb.
        </p>
        <p className="text-justify pt-2 font-weight-normal">
          Exemption from or limitation on liability for damages under the
          previous paragraphs also applies to any claims that may exist against
          employees or agents of Megan Jewellery Sdn Bhd.
        </p>
        <p className="text-justify pt-2 font-weight-normal">
          Given the resolution of photos displayed online could be compressed
          due to different devices are being used to view the product, Megan
          Jewellery is not liable to any product claiming if product looks
          different from photo revealed.
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text ">
          12. Severability Clause
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          If one of the provisions of these General Terms and Conditions is or
          becomes invalid or unenforceable, that will not affect the effect of
          the other provisions.
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text ">
          13. Prohibition against Assignment
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          You may assign your rights under this contract to third parties only
          with the consent of Megan Jewellery Sdn Bhd.
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text ">
          14. Applicable Law
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          Contracts entered into under these General Terms and Conditions are
          subject to Malaysia law to the exclusion of any referrals to foreign
          law. This choice of law only applies to the extent that there are no
          pre-emptive provisions of the law of the state in which the consumer
          has his usual residence prohibiting it. There is no exclusive venue
          for all disputes that may arise from or in connection with these
          General Terms and Conditions. Megan Jewellery Sdn Bhd is also
          authorized to sue at the customer’s location.
        </p>
        <h5 className=" pt-2 font-weight-bold megan-text ">
          15. MEGAN Product & Goods Sold
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          Any items sold cannot be returned for cash
        </p>
        <p className="text-justify font-weight-normal">
          When necessary, for safety purposes of tiny parts such as springs,
          screws, hooks and other tiny part may be consist of difference gold
          purity or other materials.
        </p>
        <p className="text-justify font-weight-normal mb-5">
          There will be minor differences between 0.01 – 0.03 grams of weight
          jewellery from original purchase receipt
        </p>
      </Container>
      <Footer />
    </div>
  );
}

export default TermsConditions;
