import Footer from "components/Footers/Footer";

import React from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";

// reactstrap components

// core components

function Error404() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("error-404");

    return function cleanup() {
      document.body.classList.remove("error-404");
    };
  });
  return (
    <div className="h-100">
      <Container
        className="text-center"
        fluid
        style={{
          background: "linear-gradient(180deg, #eae1da, white)",
          minHeight: "calc(100vh - 43px)",
        }}
      >
        <div className="position-relative py-5">
          <div className="mx-auto d-flex justify-content-center">
            <span>
              <img
                src={require("assets/img/Page/404/circle0.png")}
                alt=""
                className="img-fluid"
              />
            </span>
          </div>
          <h1 className="font-weight-bold">
            Oops! That Page Can&apos;t Be Found.
          </h1>
          <h3> Let&apos;s go back by clicking</h3>
          <Link to="/">
            <Button className="mt-3 mb-3 button-template" size="lg">
              Back to Homepage
            </Button>
          </Link>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default Error404;
