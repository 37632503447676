import axios from "axios";
import React, { createContext, useMemo } from "react";
import PropTypes from "prop-types";
// import UserContext from "hooks/UserContext";

const ApiContext = createContext(null);

export function ApiProvider({ children }) {
  // const { user } = useContext(UserContext);

  const Axios = useMemo(() => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    });

    // if (user) {
    const responseSuccessHandler = (response) => response;

    const responseErrorHandler = async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;

        // return originalRequest object with Axios.
        return axiosInstance(originalRequest);
      }

      return Promise.reject(error);
    };

    axiosInstance.interceptors.response.use(
      (response) => responseSuccessHandler(response),
      (error) => responseErrorHandler(error)
    );
    // }
    return axiosInstance;
  }, []);

  return <ApiContext.Provider value={Axios}>{children}</ApiContext.Provider>;
}
ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApiContext;
