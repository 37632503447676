import React, { useContext } from "react";
// reactstrap components
import { Container, Row, Col, Input, Form } from "reactstrap";
import Button from "components/ButtonSpinner";
import "./welcomePart.css";

// core components

import Footer from "components/Footers/Footer";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiContext from "hooks/ApiContext";
import { toast } from "react-toastify";

function Welcome() {
  const navigate = useNavigate();
  const Axios = useContext(ApiContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (data, { resetForm }) => {
      Axios.get(`public/Account/CheckValidEmail/${data.email}`)
        .then((res) => {
          resetForm();
          if (res.data.email && res.data.fullname) {
            navigate(
              `/register?fullname=${res.data.fullname}&email=${res.data.email}`,
              { state: "This is real process" }
            );
          } else {
            toast.error("Something error, please try again or report the bug");
          }
        })
        .catch((err) => {
          if (!err.response) {
            toast.error("Something error, please try again");
          } else {
            navigate(`/result`, {
              state: err.response.data.MESSAGE
                ? err.response.data.MESSAGE
                : "Something error, please try again",
            });
          }
        });
    },
  });

  return (
    <div
      style={{ backgroundColor: "#f8f4ed", minHeight: "100vh" }}
      className="d-flex flex-column justify-content-center"
    >
      <Container className="Welcome mt-auto w-100">
        <Row className=" justify-content-center">
          <Col xl="10">
            <div className="text-center text-white">
              <img
                width="60%"
                className="img-fluid align-self-center"
                src={require("assets/img/logo/MJ-Logo-Brown.png")}
                alt="Megan Jewellery"
              />
              <h5 className="mt-3 mb-2">Pre-Register for special rewards</h5>
              <Form onSubmit={formik.handleSubmit}>
                <div className="d-flex flex-row justify-content-center">
                  <Input
                    style={{
                      borderRadius: "5px 0px 0px 5px",
                      maxWidth: "265px",
                    }}
                    required
                    onChange={formik.handleChange}
                    placeholder="Email *"
                    id="email"
                    name="email"
                    value={formik.values.email}
                    type="email"
                  />
                  <Button
                    style={{
                      backgroundColor: "#8f7d65",
                      border: 0,
                      borderRadius: "0px 5px 5px 0px",
                      textTransform: "capitalize",
                    }}
                    id="submitButton"
                    type="submit"
                    disabled={formik.errors.email}
                  >
                    Register
                  </Button>
                </div>
                <div
                  className={`invalid-feedback ${
                    formik.values.email && formik.errors.email && "d-block"
                  }`}
                >
                  Email Address is required.
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
}

export default Welcome;
