import React from "react";
import { Col, Container, Row, Table } from "reactstrap";
import Footer from "components/Footers/Footer";

function ReturnPolicy() {
  return (
    <div>
      <div>
        <div className="section-space">
          <div
            className="contactus-1 py-5"
            style={{
              backgroundColor: "#F5F0E5",
              backgroundPosition: "center",
            }}
          >
            <div className="filter" />
            <div className=" content-center text-center">
              <h1
                className="font-weight-bold text-dark"
                style={{ fontSize: "40px" }}
              >
                Return Policy
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Container className="megan-bodytex">
        <h4
          className="p-2 font-weight-bold pt-5 mt-3 mb-2 megan-text"
          style={{ fontSize: "30px" }}
        >
          Return Terms & Conditions
        </h4>
        <div>
          <ul
            className="font-weight-normal"
            style={{
              fontSize: "15px",
              lineHeight: "1.5em",
              marginBottom: "5px",
            }}
          >
            <li className="pb-2">
              Customer who wishes to return product(s) after purchased, he/she
              shall do so within 7 days from the date of purchase (West
              Malaysia) and 10 days from the date of purchase (East Malaysia)
              with Customer & Affiliate Service Officer.
            </li>
            <li className="pb-2">
              Customer shall bear the freight charge (if any) on returning
              product(s) and be responsible for the safe delivery of the
              product(s). We do not guarantee that we will receive your return
              product(s).
            </li>
            <li className="pb-2">
              Unauthorized returns may be returned to customer at customer’s
              expense, or subject to additional charges without credit being
              issued to customer. This policy applies to all customers unless
              superseded by a separate written agreement that includes specific
              return goods terms and conditions.
            </li>
            <li className="pb-2">
              Refund does not apply to customization order i.e. engraving,
              resizing etc.
            </li>
            <li className="pb-2">
              Order(s) purchase with promotion code cannot be refunded.
            </li>
            <li className="pb-2">
              Returning of product(s) will be eligible if it is listed under the
              valid return reasons.
            </li>
            <li className="pb-2">
              Customer who requests the return must provide
              <ul>
                <li>Unboxing video which shows the product problem.</li>
                <li>Order invoice</li>
              </ul>
            </li>
            <li className="pb-2">
              The return request has to be investigated within 1-3 working days
              and approved by operation manager before sending back to Megan
              Jewellery.
            </li>
            <li className="pb-2">
              Once return request is approved, the return product(s) must remain
              its
              <ul>
                <li>Original condition and tag intact</li>
                <li>Original packing (Packaging(s) must be returned)</li>
                <li>
                  It must be unaltered, and the weight must be same as purchased
                </li>
              </ul>
            </li>
            <li className="pb-2">
              You shall fill up a return/refund form provided by with Customer &
              Affiliate Service Officer after return product(s) shipped.
            </li>
            <li className="pb-2">
              Megan Jewellery will validate the return product(s) within 1-3
              working after receiving the parcel.
            </li>
            <li className="pb-2">
              If the return is successfully validated after quality inspection,
              we will make the refund within 10 working days to your e-wallet.
              Refund in the form of credit into wallet are not exchangeable to
              cash.
            </li>
          </ul>
        </div>
        <div className="p-2 font-weight-normal pt-2 mt-5 megan-bodytex">
          <h5
            className="font-weight-bold megan-text"
            style={{
              fontSize: "30px",
            }}
          >
            List of Valid Return Reasons:
          </h5>
          <ul>
            <li>
              Please note that returns
              <strong>&nbsp;maybe rejected&nbsp;</strong>
              when the request does not comply our return policy or return under
              wrong return reason.
            </li>
          </ul>
          <Row className="justify-content-start">
            <Col md="10">
              <Table bordered>
                <tbody>
                  <tr className="megan-bodytex">
                    <td className="font-weight-bold text-left">
                      &nbsp;&nbsp;&nbsp;Type
                    </td>
                    <td className="font-weight-bold text-left">
                      &nbsp;&nbsp;&nbsp;Reasons
                    </td>
                  </tr>
                  <tr className="megan-bodytex">
                    <td className="font-weight-normal megan-bodytex">
                      &nbsp;&nbsp;&nbsp;Product isn’t matching with the product
                      description
                    </td>
                    <td className="font-weight-normal text-left">
                      <ol className="text-left">
                        <li>&nbsp;&nbsp;Wrong product</li>
                        <li>&nbsp;&nbsp;Wrong color</li>
                        <li>&nbsp;&nbsp;Wrong size</li>
                      </ol>
                    </td>
                  </tr>
                  <tr className="text-left">
                    <td>&nbsp;&nbsp;&nbsp;Incomplete product</td>
                    <td>
                      <ol>
                        <li>&nbsp;&nbsp;Missing quantity</li>
                      </ol>
                    </td>
                  </tr>
                  <tr className="text-left">
                    <td>&nbsp;&nbsp;&nbsp;Product is damaged and defective</td>
                    <td>
                      <ol>
                        <li>&nbsp;&nbsp;Dented</li>
                        <li>&nbsp;&nbsp;Scratched</li>
                        <li>&nbsp;&nbsp;Broken</li>
                        <li>&nbsp;&nbsp;Defect</li>
                      </ol>
                    </td>
                  </tr>
                  <tr className="megan-bodytex">
                    <td>&nbsp;&nbsp;&nbsp;Size is not fit</td>
                    <td>
                      <ol>
                        <li>&nbsp;&nbsp;Cannot be wear</li>
                      </ol>
                    </td>
                  </tr>
                  <tr className="megan-bodytex">
                    <td>&nbsp;&nbsp;&nbsp;Customer does not receive product</td>
                    <td>
                      <ol>
                        <li>&nbsp;&nbsp;Parcel lost in transit</li>
                      </ol>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
        <div className="p-2 pt-2 mt-5">
          <h5
            className="font-weight-bold mb-3 megan-text"
            style={{
              fontSize: "30px",
            }}
          >
            Refund Timeframe:
          </h5>
          <Row>
            <Col md="10">
              <Table bordered>
                <tbody>
                  <tr className="text-center">
                    <td className="font-weight-bold megan-bodytex">
                      Payment Method
                    </td>
                    <td className="font-weight-bold megan-bodytex">
                      Refund Destination
                    </td>
                    <td className="font-weight-bold megan-bodytex">
                      Refund Destination
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-normal text-center">
                      Megan e-wallet
                    </td>
                    <td className="font-weight-normal text-center">
                      Megan e-wallet
                    </td>
                    <td className="font-weight-normal text-center">
                      Within 7-10 working days
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-normal text-center">
                      Online Banking
                    </td>
                    <td className="font-weight-normal text-center">
                      Megan e-wallet
                    </td>
                    <td className="font-weight-normal text-center">
                      Within 7-10 working days
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-normal text-center">
                      Debit/Credit Card
                    </td>
                    <td className="font-weight-normal text-center">
                      Megan e-wallet
                    </td>
                    <td className="font-weight-normal text-center">
                      Within 7-10 working days
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
        <div className="p-2 pt-2 mt-3 megan-bodytex">
          <h5
            className="font-weight-bold mb-3 megan-text"
            style={{
              fontSize: "30px",
            }}
          >
            Return Procedures:
          </h5>
          <p>
            Step 1: &nbsp;&nbsp;Record unboxing video and checking the product
            condition.
          </p>
          <p>
            Step 2: &nbsp;&nbsp;Request for return/refund on Facebook Messenger.
          </p>
          <p>
            Step 3: &nbsp;&nbsp;Ship return product(s) & fill return/refund
            form.
          </p>
          <p>
            Step 4: &nbsp;&nbsp;Returned product(s) will be validated by Megan
            Jewellery.
          </p>
          <p>
            Step 5: &nbsp;&nbsp;Refunded will be processed upon successful
            validation.
          </p>
          <br />
          <br />
          <h5
            className="font-weight-bold mb-3 megan-text"
            style={{
              fontSize: "30px",
            }}
          >
            Return AWB Details:
          </h5>
          <Row className="justify-content-start">
            <Col md="10">
              <Table bordered>
                <tbody>
                  <tr className="text-left">
                    <td className="font-weight-bold megan-bodytex">Name</td>
                    <td className="font-weight-bold megan-bodytex">
                      Refund Destination
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-normal megan-bodytex">
                      Item Description
                    </td>
                    <td className="font-weight-normal megan-bodytex">
                      Spare parts
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-normal megan-bodytex">
                      Contact
                    </td>
                    <td className="font-weight-normal megan-bodytex">
                      012 - 470 3788
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-normal megan-bodytex">
                      Address
                    </td>
                    <td className="font-weight-normal megan-bodytex">
                      19, Tingkat Kikik 7, Taman Inderawasih, 13600 Perai, Pulau
                      Pinang.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>

        <p className=" pt-4 pb-5 text-left">
          Please refer to&nbsp;
          <a className="megan-text" href="/termsconditions">
            terms and conditions
          </a>
          &nbsp;page for the complete Return Terms and Conditions.
        </p>
      </Container>

      <Footer />
    </div>
  );
}

export default ReturnPolicy;
