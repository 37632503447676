/* eslint-disable no-shadow */
import React, { useContext, useState } from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";
import ApiContext from "hooks/ApiContext";
import "./feedbackform.css";
import { toast } from "react-toastify";

// The PopOutForm component
function PopOutForm() {
  const Axios = useContext(ApiContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [feedbackText, setFeedbackText] = useState("");

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };
  const handleFileChange = (event) => {
    const newSelectedFiles = Array.from(event.target.files);
    if (selectedFiles.length + newSelectedFiles.length <= 3) {
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    } else {
      // Display an error message or prevent further file selection
      // depending on your application's requirements.
      toast.error("Maximum of three files allowed.");
    }
  };
  const handleRemoveFile = (index) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
  };
  const resetForm = () => {
    setSelectedFiles([]);
    setFeedbackText("");
  };
  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("Description", feedbackText);
      selectedFiles.forEach((file) => {
        formData.append(`AttachmentList`, file);
      });

      const response = await Axios.post("/public/Account/Feedback", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Feedback submitted:", response.data);
      resetForm();
      toggleForm();
      // You can handle success here, e.g., showing a success message to the user.
    } catch (error) {
      toast.error(error.response.data.MESSAGE);
      // You can handle errors here, e.g., showing an error message to the user.
    }
  };

  return (
    <div className="feedback-form">
      {isOpen && (
        <div
          className="feedback-form-content"
          style={{
            border: "solid 5px",
            borderColor: "#F7F1E5",
          }}
        >
          <div className="text-center">
            <img
              src={require("assets/img/logo/Megan logo.png")}
              alt="Feedback Icon"
              className="flower-icon m-0 p-0"
              height="25px"
              width="25px"
            />
            <span className="mt-2 font-weight-bold">Your Opinion Matters!</span>
            <img
              src={require("assets/img/logo/Megan logo.png")}
              alt="Feedback Icon"
              className="flower-icon m-0 p-0"
              height="25px"
              width="25px"
            />
            <hr style={{ borderColor: "#CCA792" }} />
            <small className="font-weight-bold">
              ✨ We&rsquo;d love to hear your thoughts and
              <br />
              feedback about our newly launched website!
            </small>
            <div className="text-left pt-2 font-weight-bold">
              <Input
                style={{ backgroundColor: "#eae0da" }}
                placeholder="Write your feedback here..."
                rows="5"
                required
                type="textarea"
                id="Message"
                name="Message"
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value)}
              />
            </div>
            <div className="file-input-container">
              <Label htmlFor="fileInput" className="custom-file-label">
                Choose a file
              </Label>
              <Input
                multiple
                type="file"
                id="fileInput"
                accept="image/*, video/*"
                className="custom-file-input"
                onChange={handleFileChange}
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
              {selectedFiles.slice(0, 3).map((file, index) => (
                <div key={file.id} className="mt-3 preview-container">
                  <Row>
                    <Col className="ml-3">
                      <Button
                        id="remove-button"
                        onClick={() => handleRemoveFile(index)}
                      >
                        X
                      </Button>
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Preview"
                        className="file-preview"
                        height="80px"
                        width="80px"
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </div>
          <Button
            className="mt-3 mb-0 button-white-template"
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </div>
      )}
      <div className="feedback-icon " onClick={toggleForm} aria-hidden="true">
        <img
          src={require("assets/img/feedbackgif.gif")}
          alt="Feedback Icon"
          className={`flower-icon `}
        />
      </div>
    </div>
  );
}

export default PopOutForm;
