import PopOutForm from "components/FeedbackForm/Feedbackform";
import React from "react";
import { ToastContainer } from "react-toastify";
// reactstrap components

function Footer() {
  return (
    <>
      <ToastContainer
        bodyStyle={{ whiteSpace: "pre-line" }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="colored"
      />
      <footer className="footer bg-transparent pb-1 pt-5" style={{ bottom: 0 }}>
        <PopOutForm />
        <div className="links">
          <span>
            {`© ${new Date().getFullYear()} All right reserved Megan Jewellery`}
          </span>
        </div>
      </footer>
    </>
  );
}

export default Footer;
