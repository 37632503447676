import React, { useContext } from "react";
// reactstrap components
import {
  Card,
  CardTitle,
  Container,
  Row,
  Col,
  Label,
  Form,
  InputGroup,
  Input,
  Modal,
  ModalBody,
  CardBody,
  ModalHeader,
} from "reactstrap";
import Button from "components/ButtonSpinner";
import "./registerPart.css";

// core components

import Footer from "components/Footers/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Datetime from "react-datetime";
import ApiContext from "hooks/ApiContext";
import { toast } from "react-toastify";

function Register() {
  const navigate = useNavigate();

  const location = useLocation();
  const message = location.state;

  if (message !== "This is real process") {
    window.location.href = "/";
  }

  const Axios = useContext(ApiContext);
  const [otpModel, setOtpModel] = React.useState(false);
  const [tempContact, setTempContact] = React.useState("");
  const [otpField, setOtpField] = React.useState(["", "", "", "", "", ""]);

  const inputRefs = React.useRef([]);

  const [timeRemaining, setTimeRemaining] = React.useState(0);
  const [isCounting, setIsCounting] = React.useState(false);

  const [isResend, setIsResend] = React.useState(false);

  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required("Fullname is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(15, "Password must not exceed 15 characters"),
    gender: Yup.object().required("Gender is required"),
    dateofbirth: Yup.date().required("Date of Brith is required"),
    contactCheck: Yup.boolean().isTrue("Required on validate contact number"),
    newsletter: Yup.boolean(),
    MatchPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  });

  const validationContact = Yup.object().shape({
    contact: Yup.string()
      .required("Contact is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .matches(
        /^(\+?01)[0-9]-?[0-9]{7,8}$/,
        "Format incorrect, Format must be 01xxxxxxxx and between 10-12 digits"
      ),
  });

  // formik submit for Contact number OTP
  const formikOtp = useFormik({
    initialValues: {
      contact: "",
    },
    validationSchema: validationContact,
    onSubmit: (data, { setSubmitting }) => {
      Axios.post(`public/Account/RequestOTP`, { contactNo: data.contact })
        .then(() => {
          setOtpModel(true);
          setSubmitting(false);
          setIsCounting(true);
          setTempContact(`${data.contact}`);
        })
        .catch((err) => {
          setSubmitting(false);
          toast.error(err.response.data.MESSAGE);
        });
    },
  });

  // Formik for register part
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullname: new URLSearchParams(window.location.search).get("fullname"),
      password: "",
      gender: "",
      contactCheck: false,
      dateofbirth: "",
      newsletter: true,
    },
    validationSchema,
    onSubmit: (data) => {
      Axios.post("public/Account/Phase1AffiliateRegistration", {
        email: new URLSearchParams(window.location.search).get("email"),
        Fullname: data.fullname,
        password: data.password,
        gender: data.gender.value,
        contact: tempContact,
        dateofbirth: data.dateofbirth,
        newsletter: data.newsletter,
      })
        .then((res) => {
          // resetForm();
          navigate(`/result`, { state: res.data.MESSAGE });
        })
        .catch(() => {
          navigate(`/result`, {
            state: "Something error, please try again",
          });
        });
    },
  });

  // check sms otp correct or not
  const ValidateOtp = () => {
    Axios.post(`public/Account/VerifyOTP`, {
      contactNo: tempContact,
      OTPCode: otpField.join(""),
    })
      .then(() => {
        formik.setFieldValue("contactCheck", true);
        toast.success("Validate Complete");
        setOtpModel(false);
      })
      .catch((err) => {
        formik.setFieldValue("contactCheck", false);
        toast.error(err.response.data[0].MESSAGE);
      });
  };

  // resend the otp
  const ResendOTP = async () => {
    setIsResend(true);
    await Axios.put(`public/Account/ResendOTP`, { contactNo: tempContact })
      .then(() => {
        setIsCounting(true);
        toast.success("OTP had resend, please check your SMS");
      })
      .catch((err) => {
        formik.setFieldValue("contactCheck", false);
        toast.error(err.response.data[0].MESSAGE);
      });
    setIsResend(false);
  };

  // timer for 60s
  React.useEffect(() => {
    let intervalId;

    if (isCounting) {
      setTimeRemaining(60);
      intervalId = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isCounting]);

  React.useEffect(() => {
    if (timeRemaining === 0) {
      setIsCounting(false);
      setTimeRemaining(0);
    }
  }, [timeRemaining]);

  // auto focus to next when input on OTP field
  const handleInputChange = (index, event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      const updatedOtp = [...otpField];
      updatedOtp[index] = value;
      setOtpField(updatedOtp);

      // Move focus to the next input field
      if (index < inputRefs.current.length - 1 && value) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleInputPaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain");
    if (/^\d*$/.test(pasteData)) {
      const updatedOtp = [...otpField];
      for (let i = 0; i < Math.min(pasteData.length, 6); i += 1) {
        updatedOtp[i] = pasteData.charAt(i);
      }
      setOtpField(updatedOtp);
    }
  };

  // ref for OTP field
  const setRef = (index, el) => {
    inputRefs.current[index] = el;
  };

  if (message !== "This is real process") {
    return null;
  }

  return (
    <div
      style={{ backgroundColor: "#f8f4ed", minHeight: "100vh" }}
      className="d-flex flex-column justify-content-center"
    >
      <Modal centered isOpen={otpModel}>
        <ModalHeader className="py-2">
          <Button
            className="btn-link p-0 float-right"
            style={{ fontSize: "20px" }}
            onClick={() => setOtpModel(false)}
          >
            X
          </Button>
        </ModalHeader>
        <ModalBody>
          <Container className=" height-100 d-flex justify-content-center align-items-center">
            <div className="position-relative">
              <Card className="card-plain p-2 text-center">
                <CardBody>
                  <h6>
                    Please enter the One-Time Password (OTP) you received to
                    complete the verification process.
                  </h6>
                  <div
                    id="otp"
                    className="inputs d-flex flex-row justify-content-center mt-2"
                  >
                    {otpField.map((value, index) => (
                      <input
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className="m-2 pl-0 text-center form-control rounded"
                        type="text"
                        value={value}
                        maxLength={1}
                        onChange={(event) => handleInputChange(index, event)}
                        onPaste={handleInputPaste}
                        onKeyUp={(event) => {
                          // Move focus to the previous input field if the current field is empty and Backspace is pressed
                          if (
                            event.key === "Backspace" &&
                            !event.target.value &&
                            index > 0
                          ) {
                            inputRefs.current[index - 1].focus();
                          }
                        }}
                        ref={(el) => setRef(index, el)}
                      />
                    ))}
                  </div>
                  <div className="mt-4">
                    <Button
                      disabled={
                        formik.values.contactCheck ||
                        Object.keys(formikOtp.errors).length > 0
                      }
                      className="btn btn-danger px-4 validate"
                      onClick={() => ValidateOtp()}
                      loading={formikOtp.isSubmitting}
                    >
                      {formikOtp.isSubmitting ? " Loading..." : "Validate"}
                    </Button>
                  </div>
                  <div className="mt-4">
                    Didn&apos;t recieved?
                    {timeRemaining === 0 ? (
                      <Button
                        type="button"
                        className="btn btn-link py-0 px-1 ml-2"
                        style={{ fontSize: "15px" }}
                        onClick={() => ResendOTP()}
                        loading={isResend}
                      >
                        {isResend ? "Sending..." : "Click Resend"}
                      </Button>
                    ) : (
                      <span>
                        &nbsp;Wait&nbsp;
                        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                        {timeRemaining}&nbsp;s
                      </span>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Container>
        </ModalBody>
      </Modal>
      <Container className="register  m-auto w-100">
        <Row className="justify-content-center">
          <Col xl="5" lg="6" md="6" sm="12" xs="12" className="px-0">
            <img
              className="img-fluid h-100"
              src={require("assets/img/MicrosoftTeams-image.png")}
              alt="Megan Jewellery"
            />
          </Col>
          <Col xl="5" lg="6" md="6" sm="12" xs="12" className="px-0 mb-0">
            <Card className="p-5 bg-white megan-text w-100 card-plain mb-0">
              <CardTitle
                className="text-center megan-text font-weight-bold mb-3 pt-0"
                tag="h4"
              >
                Register
              </CardTitle>
              <Form onSubmit={formik.handleSubmit} className="register-form">
                <InputGroup className="input-field-template-dark">
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Full Name*"
                    id="fullname"
                    name="fullname"
                    value={formik.values.fullname}
                    type="text"
                  />
                </InputGroup>
                <div className="text-danger">
                  {formik.touched.fullname && formik.errors.fullname
                    ? formik.errors.fullname
                    : null}
                </div>
                <InputGroup className="input-field-template-dark">
                  <span
                    className="input-group-text border-0"
                    style={{ borderRadius: 0 }}
                  >
                    +6
                  </span>
                  <Input
                    style={{ border: 0, borderLeft: "2px solid grey" }}
                    onChange={formikOtp.handleChange}
                    onBlur={formikOtp.handleBlur}
                    placeholder="Contact*"
                    name="contact"
                    id="contact"
                    value={formikOtp.values.contact}
                    type="contact"
                    disabled={formik.values.contactCheck}
                  />
                  {!formik.values.contactCheck && (
                    <Button
                      disabled={
                        formikOtp.values.contact === "" || !formikOtp.isValid
                      }
                      onClick={formikOtp.handleSubmit}
                      className="font-weight-bold button-white-template ml-1"
                      loading={formikOtp.isSubmitting}
                    >
                      {formikOtp.isSubmitting ? " Loading..." : "Submit"}
                    </Button>
                  )}
                </InputGroup>
                <div className="text-danger">
                  {formikOtp.touched.contact && formikOtp.errors.contact
                    ? formikOtp.errors.contact
                    : null}
                </div>
                <div className="text-danger">
                  {formik.errors.contactCheck
                    ? formik.errors.contactCheck
                    : null}
                </div>
                <InputGroup className="input-field-template-dark">
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Password*"
                    name="password"
                    id="password"
                    value={formik.values.password}
                    type="password"
                    data-type="password"
                    autoComplete="new-password"
                  />
                </InputGroup>
                <div className="text-danger">
                  {formik.touched.password && formik.errors.password
                    ? formik.errors.password
                    : null}
                </div>
                <InputGroup className="input-field-template-dark">
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Confirm Password*"
                    name="MatchPassword"
                    id="MatchPassword"
                    value={formik.values.MatchPassword}
                    type="password"
                    data-type="password"
                    autoComplete="new-password"
                  />
                </InputGroup>
                <div className="text-danger">
                  {formik.touched.MatchPassword && formik.errors.MatchPassword
                    ? formik.errors.MatchPassword
                    : null}
                </div>
                <InputGroup className="input-field-template-dark">
                  <Input
                    type="select"
                    className="customSelect"
                    id="gender"
                    name="gender"
                    onChange={(e) => {
                      formik.setFieldTouched("gender");
                      formik.setFieldValue("gender", {
                        value: `${e.target.value}`,
                        label: `${e.target.value}`,
                      });
                    }}
                  >
                    <option disabled selected hidden>
                      Gender *
                    </option>
                    <option>Male</option>
                    <option>Female</option>
                  </Input>
                </InputGroup>
                <div className="text-danger">
                  {formik.touched.gender && formik.errors.gender
                    ? formik.errors.gender
                    : null}
                </div>
                <InputGroup className="input-field-template-dark">
                  <Datetime
                    dateFormat="DD/MM/YY"
                    inputProps={{
                      placeholder: "Tell us your birthday! DD/MM/YY *",
                    }}
                    timeFormat={false}
                    id="dateofbirth"
                    onChange={(v) => {
                      formik.setFieldTouched("dateofbirth");
                      formik.setFieldValue("dateofbirth", v);
                    }}
                    name="dateofbirth"
                    value={formik.values.dateofbirth}
                  />
                </InputGroup>
                <div className="text-danger">
                  {formik.touched.dateofbirth && formik.errors.dateofbirth
                    ? formik.errors.dateofbirth
                    : null}
                </div>
                <div style={{ paddingLeft: "1.25rem" }}>
                  <Label htmlFor="checkbox">
                    <Input
                      required
                      id="checkbox"
                      type="checkbox"
                      name="checkbox"
                      className="checkbox icon"
                    />
                    <small className="megan-text">
                      I confirm that I’ve read and agree
                      <Link to="/termsconditions" className="hrefLink">
                        &nbsp;T&Cs
                      </Link>
                      &nbsp;and&nbsp;
                      <Link to="/privacypolicy" className="hrefLink">
                        Privacy Policy
                      </Link>
                    </small>
                  </Label>
                </div>
                <div style={{ paddingLeft: "1.25rem" }}>
                  <Label htmlFor="check">
                    <Input
                      defaultChecked
                      id="check"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="checkbox"
                      name="newsletter"
                      value={formik.values.newsletter}
                      className="input checkbox icon"
                    />
                    <small className="megan-text">
                      &nbsp;I would like to receive Megan Jewellery&apos;s
                      Newsletter
                    </small>
                  </Label>
                </div>
                <div className="group text-center">
                  <Button
                    // disabled={!formik.isValid}
                    type="submit"
                    className="font-weight-bold button-white-template"
                    loading={formik.isSubmitting}
                  >
                    {formik.isSubmitting ? "Loading..." : "Create Account"}
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
}

export default Register;
