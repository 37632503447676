import Footer from "components/Footers/Footer";
import { Container } from "reactstrap";
import React from "react";

function PrivacyPolicy() {
  return (
    <div>
      <div
        className="section py-5"
        style={{
          backgroundColor: "#F5F0E5",
          backgroundPosition: "center",
        }}
      >
        <h1 className="text-center font-weight-bold megan-text">
          Privacy Policy
        </h1>
      </div>
      <Container>
        <h6 className="text-left mt-5 mb-3">Version: Dec 2018</h6>
        <p className="text-justify font-weight-normal">
          This Privacy Policy describes how Megan Jewellery Sdn Bhd and its
          affiliates (collectively, “Megan”) collect, use and share information
          about you. We may change this Privacy Policy from time to time. If we
          make changes, we will post the revised version of the Privacy Policy
          on our websites and Online Shop, including www.meganjewellery.com (the
          “Sites”) and may notify you in other ways as appropriate. We encourage
          you to review this page regularly to ensure you are comfortable with
          any revisions.
        </p>
        <p className="text-justify font-weight-normal">
          By voluntarily providing your personal information to Megan, you, the
          customer, give consent to Megan to collect, hold, disclose, and use
          your personal information submitted through the Sites (and all
          personal information submitted in connection with an online purchase).
          This consent can occur by registering your contact details with Megan,
          entering into a purchase agreement, by engaging in other forms of
          communication with Megan, and otherwise interacting with Megan online
          or in our stores.
        </p>
        <h5 className="font-weight-bold pt-2 megan-text">
          What Information We Collect and Share
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          Megan processes, discloses, holds, and uses your personal information
          and data securely, fairly, and lawfully in accordance with relevant
          Malaysia PERSONAL DATA PROTECTION ACT 2010. Such processing is carried
          out electronically and automatically. Megan uses any personal
          information submitted through the Sites (and all information submitted
          in connection with an online purchase), such as name, postal address,
          email address, phone number, and credit card information, for the
          purposes of: enabling customers to purchase Megan products; market
          research with the goal of improving the website and product range;
          keeping customers informed about Megan’s market activities, goods, and
          services; and delegating repairs or other logistical tasks to third
          parties.
        </p>
        <p className="text-justify font-weight-normal">
          Personal information will not be passed on to any other third parties
          outside the Megan Group for marketing purposes without prior
          notification. Your personal data is collected, held, and used by
          Megan, which is responsible for the management of personal
          information, by Megan (Malaysia). Your consent is given to the
          disclosure, holding, and use of information by Megan and other
          companies of the Megan Group, including, but not limited to, the
          companies in Malaysia for the above mentioned purposes. In case of
          store pick-up, your personal information necessary for the pick-up
          will be disclosed to and used by the pick-up store
        </p>
        <p className="text-justify font-weight-normal">
          In the event that a store, division, assets or part or all of Megan is
          bought, sold or otherwise transferred, or is in the process of a
          potential transaction, customer information will likely be shared for
          evaluation purposes and included among the transferred business
          assets.
        </p>
        <p className="text-justify font-weight-normal">
          Megan may also disclose specific information when such disclosure
          appears necessary to comply with the law, a subpoena or other
          litigation process or to protect the interests or safety of its
          visitors and customers, employees or others. This includes exchanging
          information with other companies and organizations for fraud
          protection and credit risk reduction.
        </p>
        <h5 className="font-weight-bold pt-2 megan-text">
          Updating and Changing Your Contact Information
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          If you registered your contact details, you may view and modify,
          correct, block or delete your data—including manage your registration
          for the Newsletter, as described below—at any time by selecting
          Profile in the “My Account” area of the Sites. Your consent to the
          storage and the processing of your data can be revoked at any time by
          contacting us as described below: Contact Information
        </p>
        <h5 className="font-weight-bold pt-2 megan-text">Cookie Policy</h5>
        <p className="text-justify pt-2 font-weight-normal">
          Megan uses tracking technology (“Cookies”), which help make the Sites
          more user-friendly, efficient, and secure. This Cookie Policy provides
          you with information on the use of Cookies on the Sites.
        </p>
        <p className="text-justify font-weight-normal">
          Cookies are small text files that are stored on your PC and used by
          your browser. Most of the Cookies Megan uses are session cookies,
          which are deleted automatically at the end of your visit.
        </p>
        <p className="text-justify font-weight-normal">
          Cookies will not harm your PC and do not contain any viruses.
          Nevertheless, if you do not want to use cookies, this will not prevent
          you from accessing Megan’s websites in any way. The “help” portion of
          the toolbar on most browsers will provide information on how your
          browser may be configured so that cookies can be accepted with your
          permission or completely blocked. You may be able to change your
          browser settings so that they do not allow Cookies to be accepted or
          saved at any time. You can also delete Cookies that have already been
          saved at any time.
        </p>
        <p className="text-justify font-weight-normal">
          Kindly note however that if you decline Cookies on the Sites, some
          functions on the Sites may be limited or unavailable. As a result, to
          make it easier to use the Sites, we recommend that your browser
          settings accept Cookies on the Sites.
        </p>
        <h5 className="font-weight-bold pt-2 megan-text">Do Not Track</h5>
        <p className="text-justify pt-2 font-weight-normal">
          Do Not Track (DNT) is a privacy preference that users can set in some
          web browsers, allowing users to opt out of tracking by websites and
          online services. At the present time, the World Wide Web Consortium
          (W3C) has not yet established universal standards for recognizable DNT
          signals and therefore, Megan and the Sites do not recognize DNT.
        </p>
        <h5 className="font-weight-bold pt-2 megan-text">
          Advertising and Analytics Services
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          We engage third parties to serve advertisements on our behalf across
          the Internet and to provide analytics services. These entities may use
          cookies, web beacons and other technologies to collect information
          about your use of our Sites, such as your IP address, web browser,
          pages viewed, time spent on pages, links clicked and conversion
          information. This information may be used by us and others to, among
          other things, analyze and track data, determine the popularity of
          certain content, deliver advertising and content targeted to your
          interests on our Sites and other websites and better understand your
          online activity. For more information about Internet-based ads, or to
          opt out of having your web browsing information used for behavioral
          advertising purposes
        </p>
        <p className="text-justify font-weight-normal">
          We may also work with third parties to serve ads to you as part of a
          customized campaign on other websites or platforms. One company we
          work with is Google, and we may use various Google tools to assist
          with analytics and advertising related to our Sites.To learn how
          Google collects and processes data, including ways you may opt-out
          from receiving ads through Google tools, please visit:
          www.google.com/policies/privacy/partners.
        </p>
        <h5 className="font-weight-bold pt-2 megan-text">reCAPTCHA</h5>
        <p className="text-justify pt-2 font-weight-normal">
          To protect your submissions via internet forms on this website we use
          the reCAPTCHA service provided by Google Inc. (Google). The service
          determines whether the query was made by a human being or was the
          result of misuse, processed automatically by a computer. The service
          includes sending the IP address to Google and possibly other data if
          required by Google for reCAPTCHA. For this purpose, your entry is sent
          and used by Google. However, before doing so, Google truncates your IP
          address within the member states of the European Union or the European
          Economic Area. Only in exceptional cases is a full IP address sent to
          a Google server in the USA and truncated there. Google will use this
          information on behalf of the operator of this website for the purposes
          of assessing your use of this service. The IP address sent from your
          browser within the scope of reCaptcha is not merged with other data
          held by Google. This data collection is subject to the data protection
          regulations of Google (Google Inc.). For more information on Google’s
          privacy policy please visit
          https://www.google.com/intl/de/policies/privacy/
        </p>
        <h5 className="font-weight-bold pt-2 megan-text">Social Plug-ins</h5>
        <p className="text-justify pt-2 font-weight-normal">
          Megan uses so-called social plug-ins (in the following, plug-ins) of
          social networks such as Facebook, Twitter, YouTube, Google+, Pinterest
          or Instagram.
        </p>
        <p className="text-justify font-weight-normal">
          When you visit our Sites these plug-ins are deactivated by default,
          i.e. without your intervention they will not send any data to third
          parties. Before you are able to use these plug-ins, you must activate
          them by clicking on them. They then remain active until you deactivate
          them again or delete your Cookies. After their activation, a direct
          link to the server of the respective social network is established.
          The contents of the plug-in are then transmitted from the social
          network directly to your browser and incorporated in the website by
          it.
        </p>
        <p className="text-justify font-weight-normal">
          Please note that we have no influence on the extent of the data
          retrieved by the social networks with their plug-ins. For information
          on purpose and extent of the data retrieval by the social network
          concerned, and about the rights and settings possibilities for the
          protection of your private sphere, please refer to the privacy policy
          provided by the social network in question.
        </p>
        <p className="text-justify font-weight-normal">
          After activation of a plug-in, the social network can retrieve data,
          independently of whether you interact with the plug-in or not. If you
          are logged on to a social network, the network can assign your visit
          to the Sites to your user account.
        </p>
        <p className="text-justify font-weight-normal">
          If you are a member of a social network and do not wish it to combine
          data retrieved from your visit to our Sites with your membership data,
          you must log out from the social network concerned before activating
          the plug-ins.
        </p>
        <h5 className="font-weight-bold pt-2 megan-text">Newsletter</h5>
        <p className="text-justify pt-2 font-weight-normal">
          You can conveniently manage your registration for the
          Meganjewellery.com Newsletter in the “My Account” area. Simply enter
          your e-mail address in the field provided and then click “send”. We
          will send a confirmation e-mail to the e-mail address you give us.
          Please click on the confirmation link contained in the e-mail and you
          will receive regular information about new arrivals from Megan. Of
          course, you can also sign up for the Meganjewellery.com newsletter
          without registering in the “My Account” area. Simply use the sign up
          window on our homepage.
        </p>
        <p className="text-justify font-weight-normal">
          If you no longer wish to receive news updates from Megan simply click
          on the “Unsubscribe” link that you find at the end of each newsletter.
          Alternatively you can unsubscribe in the “My Account” area of our
          Sites.
        </p>
        <h5 className="font-weight-bold pt-2 megan-text">Security</h5>
        <p className="text-justify  pt-2 font-weight-normal">
          We take reasonable measures to help protect information about you from
          loss, theft, misuse and unauthorized access, disclosure, alteration
          and destruction.
        </p>
        <h5 className="font-weight-bold pt-2 megan-text">Children</h5>
        <p className="text-justify pt-2 font-weight-normal">
          Megan is concerned about the privacy and safety of children when they
          use the Internet. We do not knowingly collect any personal information
          from children under the age of 13 without parental consent. Our
          products are for purchase by adults only and our Sites are not
          targeted to or intended for use by children.
        </p>
        <h5 className="font-weight-bold pt-2 megan-text">
          Your Privacy Rights
        </h5>
        <p className="text-justify pt-2 font-weight-normal">
          This Privacy Policy describes how we may share your information for
          marketing purposes. You may contact us with any questions and, to the
          extent applicable, to request a list of third parties to whom we may
          disclose information for marketing purposes and the categories of
          information we may disclose.
        </p>
        <p className="text-justify font-weight-normal mb-5">
          Contact Information If you have questions about this Privacy Policy
          and Megan’s practices regarding the collection and use of your
          information, please feel free to contact us at&nbsp;
          <a href="/AboutUs/#letusknow">
            <strong className="megan-text">Contact us</strong>
          </a>
        </p>
      </Container>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
