import React, { useContext } from "react";

// reactstrap components
import { Container } from "reactstrap";
import ApiContext from "hooks/ApiContext";
import { useLocation } from "react-router-dom";

function ActivationPage() {
  const Axios = useContext(ApiContext);
  const [errorMsg, setErrorMsg] = React.useState("");

  const id = new URLSearchParams(useLocation().search).get("id");

  if (!id) {
    window.location.href = "/";
  }

  React.useEffect(() => {
    Axios.post(`/public/Account/Activate/${id}`).catch((err) =>
      setErrorMsg(err.message)
    );
  }, [Axios, id]);

  if (!id) {
    return null;
  }

  if (errorMsg === "") {
    return (
      <div
        className="page-header"
        style={{
          backgroundColor: "#EAE0DA",
        }}
      >
        <div className="content-center">
          <Container>
            <img
              width="60%"
              className="img-fluid align-self-center"
              src={require("assets/img/logo/MJ-Logo-Brown.png")}
              alt="Megan Jewellery"
            />
            <h1>THANK YOU FOR REGISTERING</h1>

            <h5>You’ve made it, your Megan account has been created!</h5>

            <p>
              You will be receiving an update on when to begin to use our new
              website.
            </p>

            <h2>Stay Tuned!</h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div
      className="page-header"
      style={{
        backgroundColor: "#EAE0DA",
      }}
    >
      <div className="content-center">
        <Container>
          <img
            width="60%"
            className="img-fluid align-self-center"
            src={require("assets/img/logo/MJ-Logo-Brown.png")}
            alt="Megan Jewellery"
          />
          <h4>{errorMsg}</h4>
        </Container>
      </div>
    </div>
  );
}

export default ActivationPage;
